import React from "react";
import Header from "../components/header"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import BotCard from "../components/botcard"
import arnold_head from "../images/arnold_head.png"
import lisa_head from "../images/lisa_head.png"
import jarvis_head from "../images/jarvis_head.png"
import { Link } from "gatsby"

const Page = class extends React.Component {
    render() {
        return(
            <>
                <Helmet>
                    <title>ChatForge | Our Chatbots</title>
                    <meta name="description" content="We have a team of bots waiting to make your business better"/>
                </Helmet>
                <body class="mainPage">
                    <Header />
                    <div class="content">
                            <div class="section">
                                <h1>
                                    Our Chatbots
                                </h1>
                                <p>We have a team of bots waiting to make your business better</p>
                                <a href="/" class="readMoreLink dont-show">Find Our App on iOS and Android</a>
                            </div>
                        </div>
                        <div class="section">
                            <h2>
                                What our bots can do:
                            </h2>
                            <div class="bot-card-container">
                                <BotCard botpic={arnold_head} botName="Arnold" botJob="Event Booking and Payment Processing" botDescription="Arnold can help customers engage with your business. He can automate processes such as scheduling and payments whilst also keeping you in the loop using Conductor."/>
                                <BotCard botpic={lisa_head} botName="Lisa" botJob="Learning and Content Discovery" botDescription="Lisa is perfect for your education institution. She can find educational material in every digital format and can intellegently recommend related topics."/>
                                <BotCard botpic={jarvis_head} botName="Jarvis" botJob="Health & Saftey" botDescription="Jarvis     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."/>
                            </div>
                            <div class=" bot-card">
                                    <h3 class="bot-card-text">
                                        FAQ Service
                                    </h3>
                                <p class="ourteambotinfo">
                                    If you have any questions, please refer them to our customer service FAQ bot.
                                </p>
                                <div id="tryit-text">
                                <Link to={`/ourbots/OurTeamBot`}>
                                    <b>Read More About Our FAQ bot</b>
                                </Link>
                                </div>       
                             </div>
                        </div>
                    <Footer />
                </body>
            </>
        )   
    }    
}

export default Page
