import React from "react";
import { Link } from "gatsby";



const Page = class extends React.Component {

    render() {
        return(
            <div class="bot-card">
                <div class="bot-card-head">
                <img src={this.props.botpic} alt={this.props.botName}/>
                    <h3 class="bot-card-text">
                        {this.props.botName}
                    </h3>
                    <h4 class="bot-card-text">
                        {this.props.botJob}
                    </h4>
                </div>
                <p class="ourteambotinfo">
                {this.props.botDescription}
                </p>
                <div id="tryit-text">
                   <Link to={`/ourbots/${this.props.botName}`}>
                        <b>Read More</b>
                    </Link>
                </div>       
            </div>
        )
    }
}
export default Page